import { defineComponent } from "vue";

import SummitLink from "@/components/summit-link/index.vue";

export default defineComponent({
  name: "Summit Report",
  components: { SummitLink },

  data() {
    return {
      filesrcs: [
        { title: 'Nasarawa State Investment Incentive Inventory - 2023', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-State-Investment-Incentive-Inventory-2023', time:'Dec 29, 2023' },
        { title: 'INVESTMENT INCENTIVE INVENTORY', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-State-Investment-Incentives-Inventory', time:'' },
      ],
    }
  }, 
  methods: {
    goto(url) {
      window.open(url);
    },
  },

});
