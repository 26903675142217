<template>

  <div
    class="py-2 flex items-center"
    :class="{
      'text-white': color == 'white',
      'text-nasida-green': color == 'green',
      'dropdownlink': isDropDownLink
    }"
  >
    <a
      class="flex items-center"
      href="javascript:void(0);"
      onclick="javascipt:window.open(url)"
      terget="blank"
    >
      <span
        class="link-text "
        :class="{'text-xs': isDropDownLink, 'spaced': !isDropDownLink, 'sub_link':isSub}"
      >{{ text }}</span>
  </a>
    <router-link
      :to="url" 
      target="_blank"
      v-if="showArrow"
    >
      <div class="w-10 arrow">
        <svg
          fill="none"
          viewBox="0 0 66 24"
          class="text-xs stroke-current"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 12L61 12"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M54 5L61 12L54 19"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </router-link>
  </div>
</template>

<style src="./download-link.component.css" scoped lang="css"></style>
<script src="./download-link.component.js"></script>