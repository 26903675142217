import { defineComponent } from "vue";
import DownloadLink from "../download-link/index.vue";
import axios from 'axios';

export default defineComponent({
  name: "Summit Link",
  components: { DownloadLink },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    link: {
      type: String,
      required: true,
    },
    image: {
      type: String,
    },
  },
  computed: {
    anchorLinkText() {
      if (this.image ||  this.title) {
        return "Download";
      } else {
        return "Download";
      }
    },
  },
  
  mounted() {
      this.downloadFile();
  },
  methods: {
      downloadFile(url) {
          axios({
                url: url, //'http://localhost:8000/demo.pdf', // File URL Goes Here
                method: 'GET',
                responseType: 'blob',
            }).then((res) => {
                 var FILE = window.URL.createObjectURL(new Blob([res.data]));
                 
                 var docUrl = document.createElement('x');
                 docUrl.href = FILE;
                 docUrl.setAttribute('download', 'file.pdf');
                 document.body.appendChild(docUrl);
                 docUrl.click();
            });
      }
  }
});
